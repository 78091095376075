import React, { useState, useMemo } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import LinkIcon from '@material-ui/icons/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';

const LOGIFORM_URL_PREFIX = 'https://forms.logiforms.com';

const useStyles = makeStyles<Theme, { level: number }>((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  nested: {
    paddingLeft: (props) => theme.spacing(2 * props.level),
  },
  icon: {
    width: 25,
    minWidth: 25,
    marginLeft: '12px',
  },
}));

interface Category {
  l1Categories?: Category[];
  l2Categories?: Category[];
  l3Categories?: Category[];
  label: string;
  forms?: {
    forms: {
      title: string;
      id: {
        current: string;
      };
    }[];
    logiForms: {
      title: string;
      url: string;
    }[];
    externalUrls: {
      title: string;
      url: string;
    }[];
  };
}

interface SingleCategoryProps {
  category: Category;
  level: number;
  currentItemId?: string;
  onItemClick: (id: string, title: string) => void;
}

const SingleCategory = ({
  category,
  level,
  currentItemId,
  onItemClick,
}: SingleCategoryProps) => {
  const classes = useStyles({ level });
  const formClasses = useStyles({ level: level + 1 });
  const [open, setOpen] = useState(true);

  const forms = useMemo(() => {
    if (category.forms) {
      const _forms = [...(category.forms.forms || [])];
      _forms.push(
        ...(category.forms.logiForms?.map(({ title, url }) => ({
          title,
          id: { current: url },
        })) || [])
      );
      _forms.push(
        ...(category.forms.externalUrls?.map(({ title, url }) => ({
          title,
          id: { current: url },
        })) || [])
      );
      return _forms;
    }
    return [];
  }, [category]);

  const subCategories =
    category.l1Categories || category.l2Categories || category.l3Categories;

  return (
    <>
      <ListItem
        button
        divider
        dense
        onClick={() => setOpen(!open)}
        className={classes.nested}
        classes={{
          button: classes.button,
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>
              {category.label}
            </Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subCategories?.map((category) => (
            <SingleCategory
              key={category.label}
              category={category}
              level={level + 1}
              onItemClick={onItemClick}
              currentItemId={currentItemId}
            />
          ))}
          {forms.length > 0 &&
            forms.map(({ title, id }) => (
              <ListItem
                key={id.current}
                button
                className={formClasses.nested}
                onClick={() => onItemClick(id.current, title)}
              >
                <ListItemText
                  primary={
                    <Typography
                      variant="body1"
                      color={currentItemId === id.current ? 'error' : 'primary'}
                    >
                      {title}
                    </Typography>
                  }
                />
                {id.current.startsWith('http') &&
                  !id.current.startsWith(LOGIFORM_URL_PREFIX) && (
                    <ListItemIcon className={classes.icon}>
                      <LinkIcon />
                    </ListItemIcon>
                  )}
              </ListItem>
            ))}
        </List>
      </Collapse>
    </>
  );
};

interface MultiCategoryProps {
  categories: Category[];
  currentItemId?: string;
  onItemClick: (id: string, title: string) => void;
}

const MultiLevelCategory = ({
  categories,
  currentItemId,
  onItemClick,
}: MultiCategoryProps) => (
  <List component="nav">
    {categories.map((category) => (
      <SingleCategory
        key={category.label}
        level={0}
        category={category}
        onItemClick={onItemClick}
        currentItemId={currentItemId}
      />
    ))}
  </List>
);

export default MultiLevelCategory;
