import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import { Link } from 'gatsby-theme-material-ui';
import { ROUTES } from '../../../../utils/constants';

const useStyles = makeStyles<Theme, { level: number }>((theme) => ({
  button: {
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  nested: {
    paddingLeft: props => theme.spacing(2 * props.level),
  },
  icon: {
    width: 25,
    minWidth: 25,
    marginRight: '12px'
  },
}));

export type Category = {
  label: string;
  items?: {
    title: string;
    id: {
      current: string;
    };
  }[];
  subCategories?: Category[];
}

interface MultiLevelCategoryProps {
  category: Category;
  level: number;
  currentItemId?: string;
  onItemClick: (id: string) => void;
  getItemIcon?: (id: string) => any;
  isItemViewable?: (id: string) => boolean;
}

const MultiLevelCategory = ({ category, level, currentItemId, onItemClick, getItemIcon, isItemViewable }: MultiLevelCategoryProps) => {
  const classes = useStyles({ level });
  const itemClasses = useStyles({ level: level + 1 });
  const [open, setOpen] = useState(level < 1);
  return (
    <>
      <ListItem
        button
        divider
        dense
        onClick={() => setOpen(!open)}
        className={classes.nested}
        classes={{
          button: classes.button
        }}
      >
        <ListItemText
          primary={
            <Typography variant="body1" style={{ fontWeight: 'bold' }}>{category.label}</Typography>
          }
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {category.subCategories?.map((category) => (
            <MultiLevelCategory
              key={category.label}
              category={category}
              level={level + 1}
              onItemClick={onItemClick}
              currentItemId={currentItemId}
              getItemIcon={getItemIcon}
              isItemViewable={isItemViewable}
            />
          ))}
          {category.items?.filter(({ id }) => !isItemViewable || isItemViewable(id.current)).map(({ title, id }) => (
            <ListItem
              key={id.current}
              button
              className={itemClasses.nested}
              onClick={() => onItemClick(id.current)}
            >
              {getItemIcon && (
                <ListItemIcon className={classes.icon}>
                  {getItemIcon(id.current)}
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Link to={`${ROUTES.KNOWLEDGEBASE}/${id.current}`}>
                    <Typography
                      variant='body1'
                      color={ currentItemId === id.current ? 'error' : 'primary' }
                    >
                      {title}
                    </Typography>
                  </Link>
                }
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  )
}

export default MultiLevelCategory;
