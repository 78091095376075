import React, { useEffect, useState, useRef } from 'react';
import { Document, pdfjs } from 'react-pdf';
import throttle from 'lodash/throttle';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';
import { VariableSizeList } from 'react-window';

import PageRenderer from './PageRenderer';
import LoadingIndicator from '../LoadingIndicator';

interface PDFViewerProps {
  url: string;
}

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFViewer = ({ url }: PDFViewerProps) => {
  const pdfViewer = useRef<HTMLDivElement>(null);

  const [pdf, setPdf] = useState(null);
  const [width, setWidth] = useState(600);
  const [cachedPageDimensions, setCachedPageDimensions] = useState(null);

  /**
   * Load all pages so we can cache all page dimensions.
   *
   * @param {Object} pdf
   * @returns {void}
   */
  const cachePageDimensions = (pdfContent: any) => {
    if (!pdfContent) return;
    const promises = Array.from(
      { length: pdfContent.numPages },
      (v, i) => i + 1
    ).map((pageNumber) => {
      return pdfContent.getPage(pageNumber);
    });

    // Assuming all pages may have different heights. Otherwise we can just
    // load the first page and use its height for determining all the row
    // heights.
    Promise.all(promises).then((pages) => {
      const pageDimensions = new Map();

      for (const page of pages) {
        const w = page.view[2];
        const h = page.view[3];

        pageDimensions.set(page._pageIndex + 1, [w, h]);
      }
      setCachedPageDimensions(pageDimensions);
    });
  };

  const onDocumentLoadSuccess = (pdfContent: any) => {
    setPdf(pdfContent);
  };

  const setViewerWidth = () => {
    pdfViewer.current && setWidth(pdfViewer.current.getBoundingClientRect().width);
  };

  useEffect(() => {
    setViewerWidth();
    cachePageDimensions(pdf);
    window.addEventListener('resize', throttle(setViewerWidth, 500));
    window.addEventListener(
      'orientationchange',
      throttle(setViewerWidth, 1000)
    );

    return () => {
      window.removeEventListener('resize', throttle(setViewerWidth, 500));
      window.removeEventListener(
        'orientationchange',
        throttle(setViewerWidth, 1000)
      );
    };
  }, [pdf]);

  const computeRowHeight = (index: number) => {
    if (cachedPageDimensions) {
      const scale = width / cachedPageDimensions.get(index + 1)[0];
      return cachedPageDimensions.get(index + 1)[1] * scale;
    }

    return 768; // Initial height
  };

  return (
    <Box width="100%" ref={pdfViewer}>
      <Box display="flex" justifyContent="flex-end" mb={2}>
        <Button
          color="primary"
          variant="contained"
          endIcon={<DownloadIcon />}
          onClick={() => window.open(url)}
        >
          Download
        </Button>
      </Box>
      <Document
        file={url}
        loading={<LoadingIndicator loading />}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        {pdf && cachedPageDimensions && (
          <VariableSizeList
            height={computeRowHeight(0) * pdf.numPages}
            itemCount={pdf.numPages}
            itemSize={computeRowHeight}
            itemData={{
              width,
            }}
            overscanCount={1}
          >
            {PageRenderer}
          </VariableSizeList>
        )}
      </Document>
    </Box>
  );
};

export default PDFViewer;
