import React, { ChangeEvent } from 'react';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Avatar from '@material-ui/core/Avatar';

import OrderTable, { IOrderTable } from './OrderTable';
import colors from '../../../styles/colors';
import { urlForImage } from '../../../utils';

export interface IField {
  name: {
    current: string;
  };
  label: string;
  type: 'text' | 'textarea' | 'file' | 'select' | 'list' | 'order';
  description?: string;
  required: boolean;
  maxLength?: number;
  minLength?: number;
  options?: {
    label: string;
    thumbnail?: {
      asset: any;
    };
  }[];
  table: IOrderTable;
}

interface FieldProps {
  fileRef: any;
  field: IField;
  values: any;
  errors: any;
  touched: any;
  handleBlur: any;
  handleChange: any;
  setFieldValue: any;
}

const Field: React.FC<FieldProps> = ({
  fileRef,
  field: { type, name, label, options, table, minLength, maxLength, required },
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
}) => {
  const addFile = () => fileRef.current.click();

  const handleFileChange =
    (field: string) => (event: ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files[0];
      console.log(field)
      console.log(file);
      setFieldValue(field, file);
    };

  if (type === 'text' || type === 'textarea') {
    return (
      <TextField
        fullWidth
        required={required}
        id={name.current}
        name={name.current}
        label={label}
        variant="outlined"
        multiline={type === 'textarea'}
        rows={8}
        value={values[name.current]}
        onBlur={handleBlur}
        onChange={handleChange}
        error={touched[name.current] && Boolean(errors[name.current])}
        helperText={touched[name.current] && errors[name.current]}
      />
    );
  }

  if (type === 'file') {
    return (
      <>
        <input
          type="file"
          ref={fileRef}
          onChange={handleFileChange(name.current)}
          style={{ display: 'none' }}
        />
        <Grid container direction="row" spacing={2} alignItems="center">
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <Button variant="contained" color="primary" onClick={addFile}>
                {`${label}${required ? '*' : ''}`}
              </Button>
            </Grid>
            <Grid item>
              <Box ml={2}>
                <Typography variant="subtitle1" color="error">
                  {errors[name.current]}
                </Typography>
              </Box>
            </Grid>
          </Grid>
          {values[name.current] && (
            <Grid item>
              <Typography variant="body1">
                {values[name.current].name}
              </Typography>
            </Grid>
          )}
        </Grid>
      </>
    );
  }

  if (options) {
    if (type === 'select') {
      return (
        <TextField
          fullWidth
          required={required}
          id={name.current}
          name={name.current}
          label={label}
          variant="outlined"
          value={values[name.current]}
          onBlur={handleBlur}
          onChange={handleChange}
          select
          SelectProps={{
            multiple: false,
            value: values[name.current],
            onChange: handleChange,
          }}
          error={touched[name.current] && Boolean(errors[name.current])}
          helperText={touched[name.current] && errors[name.current]}
        >
          <MenuItem key="" value={undefined}>
            N/A
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option.label} value={option.label}>
              <Grid container direction="row" spacing={1}>
                {option.thumbnail && (
                  <Grid item>
                    <img
                      src={urlForImage(option.thumbnail.asset).width(40).url()}
                      alt=""
                    />
                  </Grid>
                )}
                <Grid item>
                  <Typography variant="h4" component="span">
                    {option.label}
                  </Typography>
                </Grid>
              </Grid>
            </MenuItem>
          ))}
        </TextField>
      );
    }

    if (type === 'list') {
      return (
        <Box border={1} borderRadius={4} borderColor={colors.grey} p={2}>
          <Grid container direction="column" spacing={1}>
            <Grid item>
              <Typography variant="body1" component="div">
                <Box fontWeight="bold">{label}</Box>
              </Typography>
            </Grid>
            <Grid
              item
              container
              direction="row"
              spacing={2}
              justifyContent="center"
            >
              {options.map((option) => (
                <Grid item key={option.label}>
                  <Button
                    variant="outlined"
                    startIcon={
                      option.thumbnail ? (
                        <Avatar
                          variant="square"
                          src={urlForImage(option.thumbnail.asset)
                            .width(40)
                            .url()}
                        />
                      ) : undefined
                    }
                    onClick={() => setFieldValue(name.current, option.label)}
                  >
                    <Typography
                      variant="body1"
                      style={{ fontWeight: option.label === values[name.current] ? 'bold' : 'normal' }}
                    >
                      {option.label}
                    </Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      );
    }
  }

  if (type === 'order' && table) {
    return (
      <Box border={1} borderRadius={4} borderColor={colors.grey} p={2}>
        <Typography variant="body1" component="div">
          <Box fontWeight="bold">{label}</Box>
        </Typography>

        <OrderTable
          table={table}
          quantities={values[name.current]}
          onChange={(rowIndex: number, _: string, newValue: any) => {
            const value = [...(values[name.current] || [])];
            value[rowIndex] = newValue;
            setFieldValue(name.current, value);
          }}
        />
        <Typography variant="body1" component="div">
          <Box
            fontWeight="bold"
            my={2}
            mr={2}
            display="flex"
            justifyContent="flex-end"
          >
            Total Quantity:{' '}
            {values[name.current]?.reduce((acc, qty) => {
              if (qty) {
                return acc + qty;
              }
              return acc;
            }, 0) || 0}
          </Box>
        </Typography>
      </Box>
    );
  }

  return null;
};

export default Field;
