import React from 'react';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';

import { IArticle } from '../../../../types';
import Article from './Article';
import colors from '../../../../styles/colors';

interface ArticleListProps {
  loading: boolean;
  error?: Error;
  articles: IArticle[];
  showSummary: boolean;
}

const ArticleList = ({
  loading,
  error,
  articles,
  showSummary,
}: ArticleListProps) => {
  if (loading) {
    return (
      <Grid container direction="row" justifyContent="center">
        <CircularProgress color="inherit" />
      </Grid>
    );
  }

  if (!articles || error) {
    return (
      <Grid container direction="row" justifyContent="center">
        <Typography variant="body1" color="error">
          There was an error when loading articles. Please try again!
        </Typography>
      </Grid>
    );
  }

  if (articles.length === 0) {
    return (
      <Grid container direction="row" justifyContent="center">
        <Typography variant="body1" color="error">
          No articles found
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      {articles.map((article) => (
        <Box key={article.id.current}>
          <Box py={2}>
            <Article article={article} showSummary={showSummary} />
          </Box>
          <Divider />
        </Box>
      ))}
    </>
  );
};

export default ArticleList;
