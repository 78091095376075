import React, { useMemo } from 'react';
import camelCase from 'lodash/camelCase';

import NumericTextField from './NumericTextField';
import Table from '../../Table';
import { urlForImage } from '../../../utils';

export interface IOrderTable {
  rows: {
    cells: {
      textContent: string;
      imageContent: {
        asset: any;
      };
    }[];
  }[];
}

interface OrderTableProps {
  table: IOrderTable;
  quantities: number[];
  onChange: (rowIndex: number, columnId: string, value: any) => void;
}

/**
 * As convention:
 *    The first column is for the label used in form submission so will not be displayed
 *    The second column must contain images
 */
const OrderTable = ({
  table: { rows },
  quantities,
  onChange,
}: OrderTableProps) => {
  const data = useMemo(() => {
    const columns = rows[0].cells.slice(1).map((cell, index) => {
      const column: any = {
        Header: cell.textContent || '',
        accessor: camelCase(cell.textContent || 'thumbnail'),
        disableFilters: true
      };

      // use `index + 1` because the first item of rows[0] has been removed
      if (rows[1].cells[index + 1].imageContent) {
        column.Cell = ({ value }) => (
          <img src={urlForImage(value).width(100).url()} alt="" />
        );
      }
      return column;
    });

    columns.push({
      Header: 'Qty',
      width: 75,
      accessor: 'qty',
      Cell: NumericTextField,
      disableFilters: true
    });

    const contents = rows.slice(1).map(({ cells }) => {
      return cells.slice(1).reduce(
        (acc, cell, index) => {
          acc[columns[index].accessor] =
            cell.textContent || cell.imageContent?.asset;
          return acc;
        },
        { id: 'id', qty: 0 }
      );
    });

    return {
      columns,
      contents,
    };
  }, [rows]);

  return (
    <Table columns={data.columns} data={data.contents} onChange={onChange} />
  );
};

export default OrderTable;
