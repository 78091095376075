import React from 'react';
import { RouteComponentProps } from '@reach/router';

import LoadingIndicator from '../../LoadingIndicator';
import CmsBlockContent from '../../CmsBlockContent';
import useGROQQuery from '../../../hooks/useGROQQuery';
import Page from '../../Page';

const CMS_PRIVATE_PAGE_QUERY = `
  *[_type=='privatePage' && uri.current == $uri && !(_id in path('drafts.**'))] {
    body
  }
`;

interface PrivatePage {
  body: any;
}

interface CmsPrivatePageProps extends RouteComponentProps {}

const CmsPrivatePage = ({ uri }: CmsPrivatePageProps) => {
  // Remove the trailing slash
  const _uri = uri.endsWith('/') ? uri.substring(0, uri.length - 1) : uri;
  const { loading, data } = useGROQQuery(CMS_PRIVATE_PAGE_QUERY, {
    variables: { uri: _uri },
  });

  const privatePage: PrivatePage = data ? data[0] : undefined;

  return (
    <Page uri={_uri}>
      <LoadingIndicator loading={loading} />
      {privatePage && <CmsBlockContent blocks={privatePage.body} />}
    </Page>
  );
};

export default CmsPrivatePage;
