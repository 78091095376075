import React from 'react';
import BlockContent from '@sanity/block-content-to-react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';

import { CMS_PROJECT_ID, CMS_DATASET } from '../utils/constants';
import { urlForImage } from '../utils';

interface CmsBlockContentProps {
  blocks: any;
}

const serializers = {
  marks: {
    textHighlightingColor: ({ mark, children }) => (
      <Box
        p={2.5}
        bgcolor={mark.hex}
        borderRadius={10}
        display="flex"
        component="span"
      >
        {children}
      </Box>
    ),
    fontColor: ({ mark, children }) => (
      <Box color={mark.hex} component="span">
        {children}
      </Box>
    ),
    subheading: ({ children }) => (
      <Typography variant='h3' component='span'>
        <Box color='#E1000F' borderBottom='solid 1px #D7D7D7'>
          {children}
        </Box>
      </Typography>
    ),
  },
  types: {
    image: ({ node }) => (
      <Box my={3} width="100%" display='flex' justifyContent='center'>
        <img src={urlForImage(node.asset).width(800).url()} alt={node.alt} style={{ width: '50%' }} />
      </Box>
    ),
    imageBlockText: ({ node }) => (
      <Box display="flex" my={3}>
        {node.contentPosition === 'left' && (
          <BlockContent
            blocks={node.content}
            serializers={serializers}
            projectId={CMS_PROJECT_ID}
            dataset={CMS_DATASET}
          />
        )}
        <Box
          maxWidth="30%"
          minWidth="30%"
          ml={1}
          mr={3}
          style={{ float: node.contentPosition === 'right' ? 'left' : 'right' }}
        >
          <img
            srcSet={`
              ${urlForImage(node.image.asset).width(320)} 320w,
              ${urlForImage(node.image.asset).width(480)} 480w,
              ${urlForImage(node.image.asset).width(800)} 800w
            `}
            sizes={`
              (max-width: 320px) 280px,
              (max-width: 480px) 440px,
              800px
            `}
            src={urlForImage(node.image.asset)}
            alt={node.alt}
            className="builder-image"
          />
        </Box>
        {node.contentPosition === 'right' && (
          <BlockContent
            blocks={node.content}
            serializers={serializers}
            projectId={CMS_PROJECT_ID}
            dataset={CMS_DATASET}
          />
        )}
      </Box>
    ),
    imageTextList: ({ node }) => (
      <Box p={2} bgcolor={node.bgColor?.hex || '#fff'}>
        {node.list.map((item, index) => (
          <Box key={index} display="flex" alignItems="center">
            <Box width="50px" mx={1}>
              <img
                src={urlForImage(item.icon.asset).width(50).url()}
                alt={item.alt}
                className="builder-image"
              />
            </Box>
            <BlockContent
              blocks={item.content}
              serializers={serializers}
              projectId={CMS_PROJECT_ID}
              dataset={CMS_DATASET}
            />
          </Box>
        ))}
      </Box>
    ),
    divider: ({ node }) => (
      <Box my={6}>
        <Divider style={{ backgroundColor: node.color?.hex || '#D7D7D7' }} />
      </Box>
    ),
    breakBlock: props => {
      const { style } = props.node;
      if (style === 'break') {
        return <Box height={25} />;
      }
      return null;
    },
  },
};

const CmsBlockContent = ({ blocks }: CmsBlockContentProps) => {
  return (
    <BlockContent
      blocks={blocks}
      serializers={serializers}
      projectId={CMS_PROJECT_ID}
      dataset={CMS_DATASET}
    />
  );
};

export default CmsBlockContent;
