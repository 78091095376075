import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import startCase from 'lodash/startCase';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

import Seo from '../../../Seo';
import PasswordChangeDrawer from './PasswordChangeDrawer';
import DetailItems from '../../../DetailItems';
import { USER_CUSTOM_ATTRIBUTES } from '../../../../utils/constants';
import useAuthentication from '../../../../hooks/useAuthentication';

interface UserProfileProps extends RouteComponentProps {}

const UserProfile: React.FC<UserProfileProps> = () => {
  const { user, userAttributes } = useAuthentication();
  const [passwordChangeDrawerVisible, setPasswordChangeDrawerVisibility] = useState(false);

  const userInfoLeft = [
    { label: 'Username', content: user.username },
    { label: 'Email', content: userAttributes.email },
    { label: 'Full Name', content: userAttributes.fullName },
  ];

  const userInfoRight = [
    { label: 'User Type', content: startCase(userAttributes.userType) },
    { label: 'Phone', content: userAttributes.phone_number },
  ];

  const userAttributesLeft: { label: string; content: string }[] = [];
  const userAttributesRight: { label: string; content: string }[] = [];

  if (user) {
    USER_CUSTOM_ATTRIBUTES.forEach(({ field, title }, index) => {
      const item = {
        label: title,
        content: Array.isArray(user[field])
          ? (userAttributes[field] as string[]).join(', ')
          : userAttributes[field]?.toString() || '',
      };

      if (index % 2 === 0) {
        userAttributesLeft.push(item);
      } else {
        userAttributesRight.push(item);
      }
    });
  }

  return (
    <>
      <Seo title="User Detail" />
      {user && (
        <Grid container direction="column" spacing={5}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                onClick={() => setPasswordChangeDrawerVisibility(true)}
              >
                Change Password
              </Button>
            </Grid>
          </Grid>
          <Grid item>
            <Box
              bgcolor="#fff"
              p={4}
              mb={3}
              boxShadow="0 5px 10px 0 rgba(0,0,0,0.15)"
            >
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <DetailItems items={userInfoLeft} />
                </Grid>
                <Grid item xs={6}>
                  <DetailItems items={userInfoRight} />
                </Grid>
                {userAttributes.userType === 'gasAgent' && (
                  <Grid item xs={6}>
                    <DetailItems items={userAttributesLeft} />
                  </Grid>
                )}
                {userAttributes.userType === 'gasAgent' && (
                  <Grid item xs={6}>
                    <DetailItems items={userAttributesRight} />
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <PasswordChangeDrawer
            open={passwordChangeDrawerVisible}
            onClose={() => setPasswordChangeDrawerVisibility(false)}
            accessToken={user.signInUserSession.accessToken.jwtToken}
          />
        </Grid>
      )}
    </>
  );
};

export default UserProfile;
