import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import DownloadIcon from '@material-ui/icons/GetApp';

import { IKnowledgebaseArticle } from '../../../../types';
import CmsBlockContent from '../../../CmsBlockContent';
import PDFViewer from '../../../PDFViewer';
import VideoPlayer from '../../../VideoPlayer';
import { urlForImage } from '../../../../utils';

interface ContentProps {
  article: IKnowledgebaseArticle;
}
const Content = ({ article }: ContentProps) => {
  const { content, pdfContent, videoContent, externalUrl, image, file } =
    article;

  if (pdfContent) {
    return <PDFViewer url={pdfContent} />;
  }

  if (videoContent) {
    return <VideoPlayer url={videoContent} />;
  }

  if (content) {
    return <CmsBlockContent blocks={content} />;
  }

  if (externalUrl) {
    return (
      <Typography variant="body1">
        The external content has been open in a new tab or window
      </Typography>
    );
  }

  if (image) {
    return (
      <img
        src={urlForImage(image.asset.url).width(800).url()}
        style={{ width: '100%' }}
        alt=""
      />
    );
  }

  if (file) {
    return (
      <Grid container direction="row" justifyContent="center">
        <Grid item xs={3}>
          <Box mt={10}>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              endIcon={<DownloadIcon />}
              onClick={() => window.open(file.url)}
            >
              Download
            </Button>
          </Box>
        </Grid>
      </Grid>
    );
  }

  return null;
};

export default Content;
