import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import colors from '../../../../styles/colors';

const useStyles = makeStyles({
  tag: {
    cursor: 'pointer',
    borderRadius: '5px',
    color: colors.white,
    fontWeight: 'bold',
  },
});

interface TagProps {
  id: string;
  selected: boolean;
  label: string;
  onClick: (id: string) => void;
}

const Tag = ({ id, selected, label, onClick }: TagProps) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.tag}
      px={1.5}
      bgcolor={colors.grey}
      onClick={() => onClick(id)}
    >
      <Typography variant={ selected? "body1" : "body2" } component="div">
        <Box fontWeight={selected ? "bold" : "normal" }>{label}</Box>
      </Typography>
    </Box>
  );
};

export default Tag;
