import React from 'react';
import ReactPlayer from 'react-player/lazy';
import Grid from '@material-ui/core/Grid';

interface VideoPlayerProps {
  url: string;
}

const VideoPlayer = ({ url }: VideoPlayerProps) => {
  return (
    <Grid container direction="row" justifyContent="center">
      <ReactPlayer
        url={url}
        playing
        width="100%"
        height={window.innerHeight - 300}
      />
    </Grid>
  );
};

export default VideoPlayer;
