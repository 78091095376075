import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';

import { useAuthentication } from '../hooks/useAuthentication';
import { ROUTES } from '../utils/constants';

interface PrivateRouteProps extends RouteComponentProps {
  component: any;
}

const PrivateRoute = ({
  component: Component,
  location,
  ...rest
}: PrivateRouteProps) => {
  const { user } = useAuthentication();

  if (!user) {
    navigate(ROUTES.LOGIN);
    return null;
  }

  return <Component {...rest} />;
};

export default PrivateRoute;
