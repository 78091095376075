import React from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';

import { IArticle } from '../../../../types';
import { ROUTES } from '../../../../utils/constants';
import { urlForImage } from '../../../../utils';

interface FeaturedArticleProps extends SizeMeProps {
  article: IArticle;
}

const FeaturedArticle: React.FC<FeaturedArticleProps> = ({ article, size }) => {
  if (!article) return null;

  return (
    <Grid container direction="column" spacing={3}>
      <Grid item>
        <Link to={`${ROUTES.COMMUNICATIONS}/${article.id.current}`}>
          <img
            src={urlForImage(article.mainImage.asset).width(Math.floor(size.width)).url()}
            style={{ width: '100%', height: size.width / 2 }}
            alt="Featured Article Image"
          />
        </Link>
      </Grid>
      <Grid item>
        <Box px={2}>
          <Link to={`${ROUTES.COMMUNICATIONS}/${article.id.current}`}>
            <Typography variant="h4" color="error">
              {article.title}
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item>
        <Box px={2}>
          <Typography variant="body1">{article.summary}</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default withSize({ monitorWidth: true })(FeaturedArticle);
