import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from 'gatsby-theme-material-ui';
import truncate from 'lodash/truncate';

import { IArticle } from '../../../../types';
import { ROUTES } from '../../../../utils/constants';
import { getTimeString, urlForImage } from '../../../../utils';

const useStyles = makeStyles({
  image: {
    width: '40%',
    maxWidth: '250px',
    marginRight: '16px',
    float: 'left'
  },
});

interface ArticleProps {
  article: IArticle;
  showSummary?: boolean;
}

const Article: React.FC<ArticleProps> = ({ article, showSummary }) => {
  const classes = useStyles();

  if (!article) return null;

  return (
    <Box style={{ overflow: 'hidden' }}>
      <Link to={`${ROUTES.COMMUNICATIONS}/${article.id.current}`}>
        <img
          src={urlForImage(article.mainImage.asset).width(200).url()}
          className={classes.image}
          alt=""
        />
      </Link>
      <Link to={`${ROUTES.COMMUNICATIONS}/${article.id.current}`}>
        <Typography variant="h4" color="error">
          {article.title}
        </Typography>
        <Typography variant="body2">
          {getTimeString(article.publishedAt)}
        </Typography>
      </Link>
      {showSummary && (
        <Box pt={2}>
          <Link to={`${ROUTES.COMMUNICATIONS}/${article.id.current}`}>
            <Typography variant="body1">{truncate(article.summary, { length: 300 })}</Typography>
          </Link>
        </Box>
      )}
    </Box>
  );
};

export default Article;
