import React from 'react';
import { SizeMeProps, withSize } from 'react-sizeme';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import useGROQQuery from '../hooks/useGROQQuery';
import Seo from './Seo';
import LoadingIndicator from './LoadingIndicator';
import { IImage } from '../types';
import { urlForImage } from '../utils';
import colors from '../styles/colors';
import NotFound from './NotFound';

const CMS_PAGE_QUERY = `
  *[_type==$pageType && uri.current == $uri && !(_id in path('drafts.**'))] {
    title,
    heroSection-> {
      title,
      description,
      image {
        asset-> { url }
      }
    }
  }
`;

type CmsPage = {
  title: string;
  heroSection?: {
    title: string;
    description: string;
    image: IImage;
  };
};

interface PageProps extends SizeMeProps {
  uri: string;
  children?: any;
}

const Page = ({ uri, children, size }: PageProps) => {
  const { loading, error, data } = useGROQQuery(CMS_PAGE_QUERY, {
    variables: {
      pageType: uri.startsWith('/home') ? 'privatePage' : 'publicPage',
      uri,
    },
  });

  if (loading) {
    return <LoadingIndicator loading={loading} />;
  }

  const page: CmsPage = data ? data[0] : undefined;
  const heroSection = page?.heroSection;

  if (error) {
    return <NotFound />;
  }

  if (!page) return null;

  return (
    <Grid container direction="column" spacing={2}>
      <Seo title={page.title} />
      <Grid item>
        <Typography variant="h4" component="div" color="error">
          <Box borderBottom={`solid 3px ${colors.danger}`}>{page.title}</Box>
        </Typography>
      </Grid>
      {heroSection && (
        <Grid item>
          <Box
            mb={heroSection.description ? 15 : 0}
            style={{ position: 'relative' }}
          >
            <img
              src={urlForImage(heroSection.image.asset.url)
                .width(Math.floor(size.width))
                .url()}
              style={{ width: '100%', height: size.width / 4 }}
              alt="Hero Image"
            />
            {heroSection.description && (
              <Box
                width="90%"
                style={{ position: 'absolute', left: '5%', bottom: '-90px' }}
              >
                <Box bgcolor="rgb(255, 255,255, 0.95)" p={3}>
                  <Typography variant="h2">{heroSection.title}</Typography>
                </Box>
                <Box bgcolor={colors.mainBg} px={1} py={4}>
                  <Typography variant="h4">
                    {heroSection.description}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      )}
      <Grid item container direction="column">
        {children}
      </Grid>
    </Grid>
  );
};

export default withSize({ monitorWidth: true })(Page);
