import React from 'react';
import { RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import NotFound from '../../../NotFound';
import Seo from '../../../Seo';
import LoadingIndicator from '../../../LoadingIndicator';
import Article from './Article';
import colors from '../../../../styles/colors';
import useGROQQuery from '../../../../hooks/useGROQQuery';

const CMS_COMMUNICATION_TAG_QUERY =
  "*[_type=='communicationArticleTag' && id.current == $id && !(_id in path('drafts.**'))] { label }";

const CMS_COMMUNICATION_TAGGED_ARTICLES_QUERY = `
  *[_type == 'communicationArticle' && references(*[_type=='communicationArticleTag' && id.current == $id]._id) && !(_id in path('drafts.**'))] {
    id {
      current
    },
    title,
    summary,
    publishedAt,
    mainImage {
      asset
    }
  }
`;

interface CommunicationArticleScreenProps
  extends RouteComponentProps<{ id: string }> {}

const Communications: React.FC<CommunicationArticleScreenProps> = ({ id }) => {
  const {
    loading,
    error,
    data: articles,
  } = useGROQQuery(CMS_COMMUNICATION_TAGGED_ARTICLES_QUERY, {
    variables: { id },
  });

  const tag = useGROQQuery(CMS_COMMUNICATION_TAG_QUERY, {
    variables: { id },
  });

  if (error || tag?.error) return <NotFound />;

  return (
    <>
      <Seo title="Communications" />
      <LoadingIndicator loading={loading} />
      {articles && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h4" component="div" color="error">
              <Box borderBottom={`solid 3px ${colors.danger}`}>
                Communications
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box width="100%" bgcolor={colors.mainBg} p={5} mb={4}>
              <Typography variant="h3">
                Tag: {tag.data?.length > 0 ? tag.data[0].label : id}
              </Typography>
            </Box>
          </Grid>
          {articles.length === 0 && (
            <Grid item>
              <Typography variant="h5" color="error">
                There are no matched articles
              </Typography>
            </Grid>
          )}
          {articles.map((article) => (
            <Grid item key={article.id.current}>
              <Box borderTop={`1px solid ${colors.grey}`} py={2}>
                <Article article={article} showSummary />
              </Box>
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default Communications;
