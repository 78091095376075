import React from 'react';

interface WebViewProps {
  uri: string;
}

const WebView = ({ uri }: WebViewProps) => (
  <iframe
    frameBorder="0"
    src={uri}
    width='100%'
    height={1200}
  />
)

export default WebView;
