import React, { memo } from 'react';
import Page from 'react-pdf/dist/esm/Page';

interface PageProps {
  index: number;
  data: any;
}

const PageRenderer = ({ index, data }: PageProps) => {
  const { width } = data;

  return (
    <Page pageNumber={index + 1} width={width} renderAnnotationLayer={false} />
  );
};

export default memo(PageRenderer);
