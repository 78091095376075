import React from 'react';
import { Router, Redirect } from '@reach/router';

import CmsPrivatePage from '../../components/screens/home/CmsPrivatePage';
import Communication from '../../components/screens/home/Communication';
import CommunicationTaggedArticles from '../../components/screens/home/Communication/CommunicationTaggedArticles';
import CommunicationArticle from '../../components/screens/home/Communication/CommunicationArticle';
import Knowledgebase from '../../components/screens/home/Knowledgebase';
import SelfService from '../../components/screens/home/SelfService';
import Feedback from '../../components/screens/home/Feedback';
import UserProfile from '../../components/screens/home/UserProfile';
import Logout from '../../components/screens/home/Logout';
import PrivateRoute from '../../components/PrivateRoute';

const Home = () => {
  return (
    <Router basepath="/home">
      <Redirect from="/" to="self-service" noThrow />
      <PrivateRoute path="/logout" component={Logout} />
      <PrivateRoute path="/communications" component={Communication} />
      <PrivateRoute
        path="/communications/tag/:id"
        component={CommunicationTaggedArticles}
      />
      <PrivateRoute
        path="/communications/:id"
        component={CommunicationArticle}
      />
      <PrivateRoute path="/knowledgebase/*" component={Knowledgebase} />
      <PrivateRoute path="/feedback" component={Feedback} />
      <PrivateRoute path="/self-service" component={SelfService} />
      <PrivateRoute path="/self-service/:id" component={SelfService} />
      <PrivateRoute path="/profile" component={UserProfile} />
      <PrivateRoute default component={CmsPrivatePage} />
    </Router>
  );
};

export default Home;
