import { useState, useEffect } from 'react';
import isEqual from 'lodash/isEqual';

import client from '../client';

const useGROQQuery = (
  query: string,
  options?: { variables: { [variable: string]: any } }
) => {
  const [loading, setLoading] = useState(false);
  const [variables, setVariables] = useState(options?.variables);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<any>();

  useEffect(() => {
    if (!isEqual(variables, options?.variables)) {
      setVariables(options?.variables);
    }
  }, [variables, options?.variables]);

  useEffect(() => {
    setLoading(true);
    client
      .fetch(query, variables)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, [query, variables]);

  return { loading, error, data };
};

export default useGROQQuery;
