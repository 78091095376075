import React, { useState, useMemo, useRef } from 'react';
import axios from 'axios';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import URL from 'url-parse';

import Field, { IField } from './Field';
import { httpClient, httpClientWithoutToken } from '../../client';
import LoadingIndicator from '../LoadingIndicator';
import { traverseField } from './util';
import AcknowledgeDialog from '../AcknowledgeDialog';

const getInitialValues = (fields: FormField[]) => {
  const initialValues = {};
  traverseField(fields, (field) => {
    if (field.type === 'order') {
      initialValues[field.name.current] = [];
    } else if (field.type === 'file') {
      initialValues[field.name.current] = undefined;
    } else {
      initialValues[field.name.current] = ''
    }
  });
  return initialValues;
};

export interface FormField extends IField {
  dependencies?: {
    dependentValue: string;
    fields: IField[];
  }[];
}

interface PureFormProps {
  id: string;
  title: string;
  handlingTeams: {
    team: {
      id: {
        current: string;
      };
    };
    assignedWhen?: {
      field: string;
      value: string;
    };
  }[];
  fields: FormField[];
}

const PureForm: React.FC<PureFormProps> = ({ id, title, fields, handlingTeams }) => {
  // TODO can not handle more than one FILE fields
  const fileRef = useRef(null);
  const [submitted, setSubmitted] = useState(false);
  const validationSchema = useMemo(() => {
    const rules = {};
    traverseField(fields, ({ type, name, label, required, minLength, maxLength }) => {
      if (required) {
        if (type === 'order') {
          rules[name.current] = Yup.array().required(`${label} required`);
        } else {
          rules[name.current] = Yup.string().required(`${label} required`);
        }
      }
      if (minLength !== undefined) {
        if (rules[name.current]) {
          rules[name.current] = rules[name.current].min(minLength, `Minimum of ${minLength} characters`);
        } else {
          rules[name.current] = Yup.string().min(minLength, `Minimum of ${minLength} characters`);
        }
      }

      if (maxLength !== undefined) {
        if (rules[name.current]) {
          rules[name.current] = rules[name.current].max(maxLength, `Maximum of ${maxLength} characters`);
        } else {
          rules[name.current] = Yup.string().max(maxLength, `Maximum of ${maxLength} characters`);
        }
      }
    });
    return Yup.object().shape(rules);
  }, [fields]);

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    resetForm,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik({
    validationSchema,
    initialValues: getInitialValues(fields),
    onSubmit: async (formValues) => {
      const uploads: { field: IField; file: File, url?: string }[] = [];
      const submissionData: any = {};
      fields.forEach((field) => {
        switch (field.type) {
          case 'file':
            if (field.type === 'file' && formValues[field.name.current]) {
              uploads.push({
                field,
                file: formValues[field.name.current]
              });
            }
            break;
          case 'order':
            submissionData[field.label] = field.table.rows.slice(1).reduce((acc, row, index) => {
              acc[row.cells[0].textContent || "Untitled"] = formValues[field.name.current] ? formValues[field.name.current][index] || 0 : 0;
              return acc;
            }, {});
            break;
          default:
            submissionData[field.label] = formValues[field.name.current];
        }
      });

      try {
        if (uploads.length > 0) {
          const uploadUrlRequests = uploads.map((upload) => {
            const filetype = upload.file.type;
            const filename = upload.file.name;
            return httpClient.post<{ url: string }>('upload-url', {
              formId: id,
              filetype,
              filename
            });
          });
          const urlRessponses = await axios.all(uploadUrlRequests);
          const uploadRequests = urlRessponses.map(({ data: { url } }, index) => {
            const fileUrl = new URL(url);
            uploads[index].url = fileUrl.origin + fileUrl.pathname;
            return httpClientWithoutToken.put(url, uploads[index].file, {
              headers: {
                'x-amz-acl': 'public-read',
                'Content-Type': uploads[index].file.type
              }
            });
          });

          await axios.all(uploadRequests);
          uploads.forEach(({ field, url }) => {
            submissionData[field.label] = url || '';
          });
        }

        const teams = handlingTeams.filter(({ assignedWhen }) => {
          if (assignedWhen) {
            return formValues[assignedWhen.field].toLowerCase() === assignedWhen.value.toLowerCase();
          }
          return true;
        }).map(({ team: { id: { current }}}) => current);

        await httpClient.post(`form/${id}`, {
          form: title,
          teams,
          data: submissionData
        });

        resetForm();
        if (fileRef.current) {
          fileRef.current.value = '';
        }
        setSubmitted(true);
      } catch (error) {
        console.log(error);
      }
    },
  });

  return (
    <form name={id} noValidate onSubmit={handleSubmit}>
      <LoadingIndicator loading={isSubmitting} />
      <AcknowledgeDialog
        open={submitted}
        title="Form Submitted!"
        content="Your request has been submitted!"
        onClose={() => setSubmitted(false)}
      />
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            {title}
          </Typography>
        </Grid>
        {fields.map((field) => (
          <Grid
            item
            container
            direction="column"
            spacing={2}
            key={field.name.current}
          >
            <Grid item>
              <Field
                fileRef={fileRef}
                field={field}
                values={values}
                errors={errors}
                touched={touched}
                handleBlur={handleBlur}
                handleChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </Grid>
            {field.dependencies && (
              <Grid item container direction="column" spacing={2}>
                {field.dependencies.map((dependency) => {
                  if (
                    dependency.dependentValue === values[field.name.current]
                  ) {
                    return dependency.fields.map((dependentField) => (
                      <Grid item key={dependentField.name.current}>
                        <Field
                          fileRef={fileRef}
                          field={dependentField}
                          values={values}
                          errors={errors}
                          touched={touched}
                          handleBlur={handleBlur}
                          handleChange={handleChange}
                          setFieldValue={setFieldValue}
                        />
                      </Grid>
                    ));
                  }
                  return null;
                })}
              </Grid>
            )}
          </Grid>
        ))}
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            fullWidth
            type="submit"
            disabled={!isValid || isSubmitting}
          >
            Submit
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

export default PureForm;
