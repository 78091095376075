import React, { useState } from 'react';
import { RouteComponentProps } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import clsx from 'clsx';

import Page from '../../Page';
import Form from '../../Form';

const useStyles = makeStyles({
  currentForm: {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    opacity: 0.8,
  },
});

interface FeedbackProps extends RouteComponentProps {}

const Feedback = ({ uri }: FeedbackProps) => {
  const classes = useStyles();
  const [currentForm, setCurrentForm] = useState<string>();

  return (
    <Page uri={uri}>
      <Grid container direction="column" spacing={4}>
        <Grid item container direction="row" justifyContent="space-evenly">
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={clsx(
                currentForm === 'feedback' && classes.currentForm
              )}
              style={{ borderColor: '#21b6a8' }}
              onClick={() => setCurrentForm('feedback')}
            >
              <Box py={1} px={2}>
                <Typography variant="h5" component="div">
                  <Box pb={1} fontWeight="bold">
                    Give us feedback
                  </Box>
                </Typography>
                <Typography variant="h6">
                  Tell us about your experience with BOC or make a suggestion
                </Typography>
              </Box>
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              fullWidth
              variant="outlined"
              className={clsx(
                currentForm === 'complaint' && classes.currentForm
              )}
              style={{ borderColor: '#ff8300' }}
              onClick={() => setCurrentForm('complaint')}
            >
              <Box py={1} px={2}>
                <Typography variant="h5" component="div">
                  <Box pb={1} fontWeight="bold">
                    Make a complaint
                  </Box>
                </Typography>
                <Typography variant="h6">
                  Let us know if you're not happy with our service
                </Typography>
              </Box>
            </Button>
          </Grid>
        </Grid>
        {currentForm && (
          <Grid item>
            <Form id={currentForm} />
          </Grid>
        )}
      </Grid>
    </Page>
  );
};

export default Feedback;
