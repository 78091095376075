import React from 'react';
import Grid from '@material-ui/core/Grid';

import useGROQQuery from '../../hooks/useGROQQuery';
import NotFound from '../NotFound';
import LoadingIndicator from '../LoadingIndicator';
import PureForm from './PureForm';

const CMS_FORM_QUERY = `
  *[_type=='form' && id.current == $id && !(_id in path('drafts.**'))] {
    id,
    title,
    handlingTeams[] {
      team-> {
        id
      },
      assignedWhen {
        field,
        value
      }
    },
    fields[]
  }
`;

interface FormProps {
  id: string;
}

const Form: React.FC<FormProps> = ({ id }) => {
  const { loading, error, data } = useGROQQuery(CMS_FORM_QUERY, {
    variables: { id },
  });

  if (error || (!loading && (!data || data.length === 0))) return <NotFound />;

  const form = data ? data[0] : undefined;

  return (
    <>
      <LoadingIndicator loading={loading} />
      {!loading && form && (
        <Grid container direction="row" justifyContent="center">
          <Grid item xs={12} sm={10}>
            <PureForm
              id={form.id.current}
              title={form.title}
              fields={form.fields}
              handlingTeams={form.handlingTeams}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default Form;
