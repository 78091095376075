import React from 'react';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Hidden from '@material-ui/core/Hidden';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import LinkIcon from '@material-ui/icons/Link';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Link } from 'gatsby-theme-material-ui';
import { FileIcon, defaultStyles } from 'react-file-icon';

import NotFound from '../../../NotFound';
import Seo from '../../../Seo';
import LoadingIndicator from '../../../LoadingIndicator';
import Article from './Article';
import { IArticle, IKnowledgebaseArticle } from '../../../../types';
import colors from '../../../../styles/colors';
import CmsBlockContent from '../../../CmsBlockContent';
import {
  getKnowledgebaseArticleType,
  getTimeString,
  urlForImage,
} from '../../../../utils';
import useGROQQuery from '../../../../hooks/useGROQQuery';
import { ARTICLE_PROJECTION } from './index';
import { ROUTES } from '../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    width: 30,
    minWidth: 30,
  },
  listItemBigIcon: {
    width: 100,
  },
}));

const CMS_COMMUNICATION_ARTICLE_QUERY = `
  *[_type == "communicationArticle" && id.current == $id && !(_id in path('drafts.**'))] ${ARTICLE_PROJECTION}
`;

interface CommunicationArticleScreenProps
  extends RouteComponentProps<{ id: string }> {}

const CommunicationArticleScreen: React.FC<CommunicationArticleScreenProps> = ({
  id,
}) => {
  const navigate = useNavigate();
  const classes = useStyles();
  const { loading, error, data } = useGROQQuery(
    CMS_COMMUNICATION_ARTICLE_QUERY,
    {
      variables: { id },
    }
  );

  const article: IArticle = data ? data[0] : undefined;

  if (error || (!loading && !article)) return <NotFound />;

  const onKnowledgebaseArticleClicked = ({
    id,
    externalUrl,
  }: IKnowledgebaseArticle) => {
    if (externalUrl) {
      // open a new tab to the urlContent
      window.open(externalUrl, '_blank');
    } else {
      // open the knowledgebase detail page
      navigate(`${ROUTES.KNOWLEDGEBASE}/${id.current}`);
    }
  };

  return (
    <>
      <Seo title={article?.title || ''} />
      <LoadingIndicator loading={loading} />
      {article && (
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="h3" component="div" color="error">
              <Box borderBottom={`solid 3px ${colors.danger}`}>
                Communication
              </Box>
            </Typography>
          </Grid>
          <Grid item>
            <Box bgcolor={colors.mainBg} p={4}>
              <Grid container direction="row" spacing={2}>
                <Grid
                  item
                  md={8}
                  xs={12}
                  container
                  direction="column"
                  spacing={2}
                >
                  <Grid item>
                    <Typography variant="body2">
                      {getTimeString(article.publishedAt)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" color="error">
                      {article.title}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{article.summary}</Typography>
                  </Grid>
                </Grid>
                <Hidden smDown>
                  <Grid item md={4} container alignItems="center">
                    <img
                      src={urlForImage(article.mainImage.asset)
                        .width(1000)
                        .url()}
                      alt=""
                      className="builder-image"
                    />
                  </Grid>
                </Hidden>
              </Grid>
            </Box>
          </Grid>
          <Grid item>
            <CmsBlockContent blocks={article.content} />
            {article.signature && (
              <Box display="flex" mt={6} mb={3}>
                <Box mr={2}>
                  <Avatar
                    style={{ width: 70, height: 70 }}
                    src={urlForImage(article.signature.avatar.asset.url)
                      .width(70)
                      .url()}
                  />
                </Box>
                <Grid
                  container
                  direction="column"
                  justifyContent="space-between"
                >
                  <Grid item>
                    <Typography variant="body1">
                      {article.signature.signOff}
                    </Typography>
                  </Grid>
                  <Grid item container direction="column">
                    <Grid item>
                      <Typography variant="body1" color="error" component="div">
                        <Box fontWeight="bold">
                          {article.signature.authorName}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="body2">
                        {article.signature.authorJobTitle}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Grid>

          {article.resources?.length > 0 && (
            <Grid item>
              <Box my={6}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h4" component="div">
                      <Box
                        fontWeight="bold"
                        borderBottom={`1px solid ${colors.grey}`}
                      >
                        Resources, Tools, Links
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List component="div" disablePadding>
                      {article.resources.map(({ title, url, file }) => (
                        <Link
                          key={title}
                          href={url || file.url}
                          rel="noreferrer"
                          target="_blank"
                        >
                          <ListItem>
                            <ListItemIcon className={classes.listItemIcon}>
                              {file ? (
                                <FileIcon
                                  {...defaultStyles[file.type]}
                                  extension={file.type}
                                />
                              ) : (
                                <LinkIcon />
                              )}
                            </ListItemIcon>
                            <ListItemText
                              primary={
                                <Typography variant="body1" component="div">
                                  <Box mx={2}>{title}</Box>
                                </Typography>
                              }
                            />
                            <ListItemSecondaryAction>
                              <ArrowRightIcon />
                            </ListItemSecondaryAction>
                          </ListItem>
                        </Link>
                      ))}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
          {article.relatedArticles?.length > 0 && (
            <Grid item>
              <Box my={6}>
                <Grid container direction="column" spacing={2}>
                  <Grid item>
                    <Typography variant="h4" component="div">
                      <Box
                        fontWeight="bold"
                        borderBottom={`1px solid ${colors.grey}`}
                      >
                        Related Articles
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid item>
                    <List component="div" disablePadding>
                      {article.relatedArticles.map((item) => {
                        if (item._type === 'communicationArticle') {
                          // Communication Article
                          return (
                            <ListItem key={item.id.current} divider>
                              <Article article={item} showSummary />
                            </ListItem>
                          );
                        }
                        // Knowledgebase Article
                        const articleType = getKnowledgebaseArticleType(item);
                        if (!articleType) return null;
                        return (
                          <ListItem
                            key={item.id.current}
                            className="clickable"
                            divider
                            onClick={() => onKnowledgebaseArticleClicked(item)}
                          >
                            {articleType && (
                              <ListItemIcon className={classes.listItemIcon}>
                                {articleType !== 'link' ? (
                                  <FileIcon
                                    {...defaultStyles[
                                      articleType === 'video'
                                        ? 'mp4'
                                        : articleType
                                    ]}
                                    extension={articleType}
                                  />
                                ) : (
                                  <LinkIcon />
                                )}
                              </ListItemIcon>
                            )}
                            <ListItemText
                              primary={
                                <Typography
                                  variant="body1"
                                  color="error"
                                  component="div"
                                >
                                  <Box mx={2}>{item.title}</Box>
                                </Typography>
                              }
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default CommunicationArticleScreen;
