import { navigate } from 'gatsby';
import { RouteComponentProps } from '@reach/router';
import { signOut } from '../../../utils/auth';

interface LogoutPageProps extends RouteComponentProps {}

const LogoutPage = (props: LogoutPageProps) => {
  signOut();
  navigate('/');

  return null;
};

export default LogoutPage;
