import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';

const NumericTextField = ({
  value: initialValue,
  row: { index },
  column: { id },
  onChange,
}) => {
  const [value, setValue] = useState(0);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    onChange(index, id, value);
  };

  return (
    <TextField
      variant="outlined"
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*'
      }}
      value={value}
      onChange={(e: React.ChangeEvent<any>) => {
        const num = parseInt(e.target.value, 10);
        if (isNaN(num) || num < 0) {
          setValue(0);
          return;
        }
        setValue(num);
      }}
      onBlur={onBlur}
    />
  );
};

export default NumericTextField;
