import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import LoadingIndicator from '../../../LoadingIndicator';
import NotFound from '../../../NotFound';
import useGROQQuery from '../../../../hooks/useGROQQuery';
import colors from '../../../../styles/colors';
import MultiLevelCategory from './MultiLevelCategory';
import WebView from '../../../WebView';
import Form from '../../../Form';
import Page from '../../../Page';
import Seo from '../../../Seo';

const LOGIFORM_URL_PREFIX = 'https://forms.logiforms.com';

const FORM_PROJECTION = `
{
  forms[]-> {
    id,
    title
  },
  logiForms[]-> {
    title,
    url
  },
  externalUrls[] {
    title,
    url
  }
}
`;

const CMS_SELF_SERVICE_PAGE_QUERY = `
  *[_type == "selfService" && !(_id in path('drafts.**'))] {
    label,
    forms ${FORM_PROJECTION},
    l1Categories[] {
      label,
      forms ${FORM_PROJECTION},
      l2Categories[] {
        label,
        forms ${FORM_PROJECTION}
      }
    }
  } | order(order asc)
`;

interface SelfServiceProps extends RouteComponentProps<{ id: string }> {}

const SelfService: React.FC<SelfServiceProps> = ({ uri, id }) => {
  const [currentFormId, setCurrentFormId] = useState<string>(id);
  const [currentTitle, setCurrentTitle] = useState<string>();

  const {
    loading,
    error,
    data: categories,
  } = useGROQQuery(CMS_SELF_SERVICE_PAGE_QUERY);

  useEffect(() => setCurrentFormId(id), [id]);

  if (error) return <NotFound />;

  // strip off the param part from uri
  const _uri = id ? uri.substr(0, uri.length - id.length - 1) : uri;

  return (
    <Page uri={_uri}>
      {currentTitle && <Seo title={currentTitle} />}
      <LoadingIndicator loading={loading} />
      {categories && (
        <Grid container direction="row" spacing={5}>
          <Grid item md={3} xs={12}>
            <Box p={2} bgcolor={colors.mainBg}>
              <MultiLevelCategory
                categories={categories}
                currentItemId={currentFormId}
                onItemClick={(_id, title) => {
                  setCurrentTitle(title);
                  if (
                    _id.startsWith('http') &&
                    !_id.startsWith(LOGIFORM_URL_PREFIX)
                  ) {
                    window.open(_id, '_blank');
                  }
                  setCurrentFormId(_id);
                }}
              />
            </Box>
          </Grid>
          {currentFormId && (
            <Grid item md={9} xs={12}>
              {!currentFormId.startsWith('http') ? (
                <Form id={currentFormId} />
              ) : currentFormId.startsWith(LOGIFORM_URL_PREFIX) ? (
                <WebView uri={currentFormId} />
              ) : (
                <Typography variant="body1">
                  The external content has been open in a new tab or window
                </Typography>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </Page>
  );
};

export default SelfService;
