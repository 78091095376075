import React, { useMemo, useState, useEffect } from 'react';
import { RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import LinkIcon from '@material-ui/icons/Link';
import Typography from '@material-ui/core/Typography';
import { FileIcon, defaultStyles } from 'react-file-icon';

import NotFound from '../../../NotFound';
import LoadingIndicator from '../../../LoadingIndicator';
import Page from '../../../Page';
import useGROQQuery from '../../../../hooks/useGROQQuery';
import colors from '../../../../styles/colors';
import { IKnowledgebaseArticle } from '../../../../types';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import { getKnowledgebaseArticleType } from '../../../../utils';
import Article from './Article';
import MultiLevelCategory, { Category } from './MultiLevelCategory';
import Seo from '../../../Seo';

export const ARTICLE_PROJECTION = `{
  id {
    current
  },
  categories[]-> {
    id {
      current 
    },
    label
  },
  title,
  content,
  "pdfContent": pdfContent->file.asset->url,
  videoContent,
  externalUrl,
  image {
    asset-> { url }
  },
  file {
    type,
    "url": asset->url
  }
}`;

export const CATEGORY_PROJECTION = `
  label,
  "items": articles[]-> {
    id {
      current
    },
    title
  },
`;

const CMS_KNOWLEGDEBASE_PAGE_QUERY = `{
  "articles": *[_type == "knowledgebaseArticle" && !(_id in path('drafts.**')) &&
    (!defined(targetUsers.productSetup) || targetUsers.productSetup[@ in $productSetup]) &&
    (!defined(targetUsers.state) || targetUsers.state[@ in $state]) &&
    (!defined(targetUsers.type) || targetUsers.type[@ in $type])
  ] ${ARTICLE_PROJECTION},
  "categories": *[_type == "knowledgebase" && !(_id in path('drafts.**'))] {
    ${CATEGORY_PROJECTION}
    subCategories[] {
      ${CATEGORY_PROJECTION}
      subCategories[] {
        ${CATEGORY_PROJECTION}
      }
    }
  } | order(order asc)
}`;

type KnowledgebasePageData = {
  articles: IKnowledgebaseArticle[];
  categories: Category[];
};

interface KnowledgebaseProps
  extends RouteComponentProps<{ [param: string]: any }> {}

const Knowledgebase: React.FC<KnowledgebaseProps> = ({ uri, ...rest }) => {
  const articleId = rest['*'];
  const { userAttributes } = useAuthentication();
  const [currentArticle, setCurrentArticle] = useState<IKnowledgebaseArticle>();
  const { loading, error, data } = useGROQQuery(CMS_KNOWLEGDEBASE_PAGE_QUERY, {
    variables: {
      type: userAttributes.type ? [userAttributes.type] : [],
      productSetup: userAttributes.productSetup || [],
      state: userAttributes.state ? [userAttributes.state] : [],
    },
  });

  const knowledgebasePage: KnowledgebasePageData = data;
  const articles = useMemo(() => {
    const _articles: { [id: string]: IKnowledgebaseArticle } = {};
    knowledgebasePage?.articles?.forEach((article) => {
      _articles[article.id.current] = article;
    });
    return _articles;
  }, [knowledgebasePage]);

  const onArticleClicked = (id: string) => {
    if (!id) {
      setCurrentArticle(undefined);
      return;
    }
    const article = articles[id];

    if (!article) {
      setCurrentArticle(null);
      return;
    }

    if (article?.externalUrl) {
      window.open(article.externalUrl, '_blank');
    }
    setCurrentArticle(undefined);
    setTimeout(() => setCurrentArticle(article), 0);
  };

  useEffect(() => onArticleClicked(articleId), [articleId, articles]);

  if (error) return <NotFound />;

  const getArticleIcon = (id: string) => {
    const article = articles[id];
    const articleType = getKnowledgebaseArticleType(article);
    let icon = <LinkIcon />;
    if (articleType !== 'link') {
      icon = (
        <Box component="span" height="100%">
          <FileIcon
            {...defaultStyles[articleType === 'video' ? 'mp4' : articleType]}
            extension={articleType}
          />
        </Box>
      );
    }
    return icon;
  };

  // Used to filtered out articles not targetted to the current user
  const isArticleViewable = (id: string) => articles[id] !== undefined;

  return (
    <Page uri={uri}>
      <LoadingIndicator loading={loading} />
      {knowledgebasePage?.categories && (
        <Grid container direction="row" spacing={5}>
          <Grid item md={3} xs={12}>
            <Box p={2} bgcolor={colors.mainBg}>
              <List component="nav">
                {knowledgebasePage?.categories.map((category) => (
                  <MultiLevelCategory
                    key={category.label}
                    level={0}
                    category={category}
                    currentItemId={currentArticle?.id.current}
                    onItemClick={onArticleClicked}
                    getItemIcon={getArticleIcon}
                    isItemViewable={isArticleViewable}
                  />
                ))}
              </List>
            </Box>
          </Grid>
          <Grid item md={9} xs={12}>
            {currentArticle && (
              <>
                <Seo title={currentArticle.title || ''} />
                <Article article={currentArticle} />
              </>
            )}
            {currentArticle === null && (
              <Box display="flex" justifyContent="center" pt={5}>
                <Typography variant="h3" color="error">
                  Article Not Found!
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </Page>
  );
};

export default Knowledgebase;
