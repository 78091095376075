import React, { useEffect, useMemo, useState, useRef } from 'react';
import { RouteComponentProps } from '@reach/router';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useFormik } from 'formik';

import NotFound from '../../../NotFound';
import LoadingIndicator from '../../../LoadingIndicator';
import FeaturedArticle from './FeaturedArticle';
import Tag from './Tag';
import useGROQQuery from '../../../../hooks/useGROQQuery';
import { useAuthentication } from '../../../../hooks/useAuthentication';
import {
  IArticle,
  ICategory,
  ISubCategory,
  ITag,
  IdAndLabel,
} from '../../../../types';
import colors from '../../../../styles/colors';
import client from '../../../../client';
import ArticleList from './ArticleList';
import Page from '../../../Page';

const ALL = 'all';

const INCREMENT_PER_LOAD = 5;
const INITIAL_LOAD = 5;

export const ARTICLE_PROJECTION = `{
  id {
    current
  },
  title,
  summary,
  publishedAt,
  mainImage {
    asset
  },
  content,
  signature-> {
    avatar {
      asset-> { url }
    },
    signOff,
    authorName,
    authorJobTitle
  },
  relatedArticles[]-> {
    _type,
    id {
      current
    },
		title,
    summary,
    publishedAt,
    mainImage {
      asset-> { url }
    },
    content,
    pdfContent,
    videoContent,
    externalUrl
  },
  resources[] {
    title,
    url,
    file {
      type,
      "url": asset->url
    }
  }
}`;

const CMS_COMMUNICATION_PAGE_QUERY = `{
  "featuredArticles": *[_type == "communicationArticle" && featured == true && !(_id in path('drafts.**'))] ${ARTICLE_PROJECTION},
  "trendingArticles": *[_type == "communicationArticle" && trending == true && !(_id in path('drafts.**'))] | order(publishedAt desc) ${ARTICLE_PROJECTION},
  "categories": *[_type == "communicationArticleCategory" && !(_id in path('drafts.**'))],
  "subCategories": *[_type == "communicationArticleSubCategory" && !(_id in path('drafts.**'))] {
    id,
    label,
    category->{
      id,
      label
    }
  },
  "tags": *[_type == "communicationArticleTag"] {
    id,
    label,
    "hasArticles": count(*[_type == 'communicationArticle' && references(^._id) && !(_id in path('drafts.**'))]) > 0
  }
}`;

const CMS_COMMUNICATION_ARTICLES_QUERY = `
  *[_type == "communicationArticle" && !(_id in path('drafts.**')) &&
    (!defined(targetUsers.productSetup) || !defined($productSetup) || targetUsers.productSetup[@ in $productSetup]) &&
    (!defined(targetUsers.state) || !defined($state) || targetUsers.state[@ in $state]) &&
    (!defined(targetUsers.type) || !defined($type) || targetUsers.type[@ in $type])
  ] | order(publishedAt desc)[$start...$end] ${ARTICLE_PROJECTION}
`;

const CMS_COMMUNICATION_ARTICLES_BY_CATEGORY_QUERY = `
  *[_type == 'communicationArticle' && !(_id in path('drafts.**')) &&
    references(*[_type=='communicationArticleCategory' && id.current == $category]._id) &&
    (!defined(targetUsers.productSetup) || !defined($productSetup) || targetUsers.productSetup[@ in $productSetup]) &&
    (!defined(targetUsers.state) || !defined($state) || targetUsers.state[@ in $state]) &&
    (!defined(targetUsers.type) || !defined($type) || targetUsers.type[@ in $type])
  ] | order(publishedAt desc) ${ARTICLE_PROJECTION}
`;

const CMS_COMMUNICATION_ARTICLES_BY_SUBCATEGORY_QUERY = `
  *[_type == 'communicationArticle' && !(_id in path('drafts.**')) &&
    references(*[_type=='communicationArticleSubCategory' && id.current == $subCategory]._id) &&
    (!defined(targetUsers.productSetup) || !defined($productSetup) || targetUsers.productSetup[@ in $productSetup]) &&
    (!defined(targetUsers.state) || !defined($state) || targetUsers.state[@ in $state]) &&
    (!defined(targetUsers.type) || !defined($type) || targetUsers.type[@ in $type])
  ] | order(publishedAt desc) ${ARTICLE_PROJECTION}
`;

const CMS_COMMUNICATION_TAGGED_ARTICLES_QUERY = `
  *[_type == 'communicationArticle' && references(*[_type=='communicationArticleTag' && id.current == $id]._id) && !(_id in path('drafts.**'))] {
    id {
      current
    },
    title,
    summary,
    publishedAt,
    mainImage {
      asset
    }
  }
`;

interface CommunicationPageData {
  featuredArticles: IArticle[];
  trendingArticles: IArticle[];
  categories: ICategory[];
  subCategories: ISubCategory[];
  tags: ITag[];
}

interface CommunicationsProps extends RouteComponentProps {}

const Communications: React.FC<CommunicationsProps> = ({ uri }) => {
  const { userAttributes } = useAuthentication();
  const [subCategoryList, setSubCategoryList] = useState<IdAndLabel[]>([]);
  const [articles, setArticles] = useState<IArticle[]>([]);
  const [articleLoadingState, setArticleLoadingState] = useState<{
    loading: boolean;
    error?: Error;
  }>({
    loading: false,
    error: undefined,
  });
  const [selectedTag, setSelectedTag] =
    useState<{ id: string; label: string }>();
  const [filteredArticles, setFilteredArticles] = useState<IArticle[]>([]);

  const articlePointer = useRef(0);
  const { loading, error, data } = useGROQQuery(CMS_COMMUNICATION_PAGE_QUERY);

  const communicationPage = data as CommunicationPageData;

  const loadMoreArticles = async (quantity: number) => {
    try {
      setArticleLoadingState({ loading: true, error: undefined });
      const data = await client.fetch(CMS_COMMUNICATION_ARTICLES_QUERY, {
        type: userAttributes.type ? [userAttributes.type] : null,
        productSetup: userAttributes.productSetup || null,
        state: userAttributes.state ? [userAttributes.state] : null,
        start: articlePointer.current,
        end: articlePointer.current + quantity,
      });
      articlePointer.current += quantity;
      setArticles([...articles, ...data]);
    } catch (error) {
      setArticleLoadingState((state) => ({ ...state, error }));
    } finally {
      setArticleLoadingState((state) => ({ ...state, loading: false }));
    }
  };

  useEffect(() => {
    loadMoreArticles(INITIAL_LOAD);
  }, []);

  const categoryList = useMemo(() => {
    const categories: {
      [id: string]: {
        id: string;
        label: string;
        subCategories: IdAndLabel[];
      };
    } = {};
    communicationPage?.categories.forEach(({ id: { current }, label }) => {
      categories[current] = {
        id: current,
        label,
        subCategories: [],
      };
    });

    communicationPage?.subCategories.forEach(
      ({
        id: { current },
        label,
        category: {
          id: { current: parentId },
        },
      }) => {
        const category = categories[parentId];
        if (category) {
          category.subCategories.push({ id: current, label });
        }
      }
    );

    return categories;
  }, [communicationPage]);

  const tagList = useMemo(() => {
    const tags: {
      [id: string]: {
        id: string;
        label: string;
      };
    } = {};

    communicationPage?.tags
      .filter(({ hasArticles }) => hasArticles)
      .forEach(({ id: { current }, label }) => {
        tags[current] = {
          id: current,
          label,
        };
      });

    return tags;
  }, [communicationPage]);

  const { values, errors, touched, setFieldValue, handleChange } = useFormik<{
    category: string;
    subCategory: string;
  }>({
    initialValues: {
      category: 'all',
      subCategory: 'all',
    },
    onSubmit: () => {},
  });

  useEffect(() => {
    setSelectedTag(undefined);
    const { category, subCategory } = values;
    if (category === ALL) {
      setFieldValue('subCategory', ALL);
      setSubCategoryList([]);
    } else {
      setSubCategoryList(categoryList[category].subCategories);
    }

    if (category !== ALL) {
      let query = CMS_COMMUNICATION_ARTICLES_BY_CATEGORY_QUERY;

      if (subCategory !== ALL) {
        query = CMS_COMMUNICATION_ARTICLES_BY_SUBCATEGORY_QUERY;
      }
      setArticleLoadingState({ loading: true, error: undefined });
      setFilteredArticles([]);

      client
        .fetch(query, {
          category,
          subCategory,
          type: userAttributes.type ? [userAttributes.type] : null,
          productSetup: userAttributes.productSetup || null,
          state: userAttributes.state ? [userAttributes.state] : null,
        })
        .then((filteredArticles) => {
          setArticleLoadingState({ loading: false, error: undefined });
          setFilteredArticles(filteredArticles);
        })
        .catch((err) => setArticleLoadingState({ loading: false, error: err }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryList, values]);

  if (error) return <NotFound />;

  return (
    <Page uri={uri}>
      <LoadingIndicator loading={loading} />
      {communicationPage && (
        <Grid container direction="column" spacing={2}>
          <Grid item container direction="row" spacing={5}>
            <Grid item xs={12} md={7} container direction="column">
              <Grid item>
                <Typography variant="body1" component="div">
                  <Box
                    borderBottom={`1px solid ${colors.grey}`}
                    fontWeight="bold"
                  >
                    Featured Article
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <Box
                  border={`1px solid ${colors.lightGrey}`}
                  mt={2}
                  height="100%"
                >
                  <FeaturedArticle
                    article={communicationPage.featuredArticles[0]}
                  />
                </Box>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} container direction="column">
              <Grid item>
                <Typography variant="body1" component="div">
                  <Box
                    fontWeight="bold"
                    borderBottom={`1px solid ${colors.grey}`}
                  >
                    Trending articles
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <ArticleList
                  loading={loading}
                  error={error}
                  articles={communicationPage.trendingArticles}
                  showSummary
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Divider />
          </Grid>
          <Grid item container direction="row" spacing={5}>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography variant="body1" component="div">
                    <Box
                      borderBottom={`1px solid ${colors.grey}`}
                      fontWeight="bold"
                    >
                      Filters
                    </Box>
                  </Typography>
                </Grid>
                <Grid item container direction="row" spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="category"
                      id="category"
                      variant="outlined"
                      select
                      label="By Category"
                      value={values.category}
                      onChange={handleChange}
                      error={touched.category && Boolean(errors.category)}
                      helperText={touched.category && errors.category}
                    >
                      <MenuItem key={ALL} value={ALL}>
                        All
                      </MenuItem>
                      {Object.values(categoryList).map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      name="subCategory"
                      id="subCategory"
                      variant="outlined"
                      select
                      label="By Sub Category"
                      value={values.subCategory}
                      disabled={subCategoryList.length === 0}
                      onChange={handleChange}
                      error={touched.subCategory && Boolean(errors.subCategory)}
                      helperText={touched.subCategory && errors.subCategory}
                    >
                      <MenuItem key={ALL} value={ALL}>
                        All
                      </MenuItem>
                      {subCategoryList.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={5} container direction="column">
              <Grid item>
                <Typography variant="body1" component="div">
                  <Box
                    mb={2}
                    borderBottom={`1px solid ${colors.grey}`}
                    fontWeight="bold"
                  >
                    Tags
                  </Box>
                </Typography>
              </Grid>
              <Grid item container direction="row" spacing={1}>
                {Object.values(tagList).map(({ id, label }) => (
                  <Grid item key={id}>
                    <Tag
                      id={id}
                      label={label}
                      selected={id === selectedTag?.id}
                      onClick={async () => {
                        if (selectedTag?.id === id) {
                          // select the same tag will unselect it and set Filtered Category to All
                          setSelectedTag(undefined);
                          setFieldValue('category', ALL);
                          return;
                        }
                        setSelectedTag({ id, label });
                        try {
                          setArticleLoadingState({
                            loading: true,
                            error: undefined,
                          });
                          const data = await client.fetch(
                            CMS_COMMUNICATION_TAGGED_ARTICLES_QUERY,
                            {
                              id,
                            }
                          );
                          setFilteredArticles(data);
                        } catch (error) {
                          setArticleLoadingState((state) => ({
                            ...state,
                            error,
                          }));
                        } finally {
                          setArticleLoadingState((state) => ({
                            ...state,
                            loading: false,
                          }));
                        }
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box my={2}>
              <Divider />
            </Box>
          </Grid>
          {!selectedTag && values.category === ALL && (
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1" component="div">
                  <Box
                    borderBottom={`1px solid ${colors.grey}`}
                    fontWeight="bold"
                  >
                    All Articles
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <ArticleList loading={false} articles={articles} showSummary />
              </Grid>
              {articleLoadingState.loading && (
                <Grid item container direction="row" justifyContent="center">
                  <CircularProgress color="inherit" />
                </Grid>
              )}
              {articleLoadingState.error && (
                <Grid item container direction="row" justifyContent="center">
                  <Typography variant="body1" color="error">
                    There was an error when loading articles. Please try again!
                  </Typography>
                </Grid>
              )}
              {!articleLoadingState.loading &&
                articlePointer.current <= articles.length && (
                  <Grid item container direction="row" justifyContent="center">
                    <Button
                      onClick={() => loadMoreArticles(INCREMENT_PER_LOAD)}
                      variant="outlined"
                      style={{ color: colors.danger }}
                      disabled={false}
                    >
                      Load more articles
                    </Button>
                  </Grid>
                )}
            </Grid>
          )}
          {(selectedTag || values.category !== ALL) && (
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="body1" component="div">
                  <Box
                    borderBottom={`1px solid ${colors.grey}`}
                    fontWeight="bold"
                  >
                    {selectedTag
                      ? `Filtered by tag "${selectedTag.label}"`
                      : 'Filtered Articles'}
                  </Box>
                </Typography>
              </Grid>
              <Grid item>
                <ArticleList
                  loading={articleLoadingState.loading}
                  error={articleLoadingState.error}
                  articles={filteredArticles}
                  showSummary
                />
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Page>
  );
};

export default Communications;
